import { executeOnSingleOrMultiple, isInArray } from "tsparticles-engine";
import { CircleShape } from "./Shapes/Circle/CircleShape";
import { Emitter } from "./Options/Classes/Emitter";
import { Emitters } from "./Emitters";
import { ShapeManager } from "./ShapeManager";
import { SquareShape } from "./Shapes/Square/SquareShape";
class EmittersPlugin {
    constructor(engine) {
        this._engine = engine;
        this.id = "emitters";
    }
    getPlugin(container) {
        return new Emitters(this._engine, container);
    }
    loadOptions(options, source) {
        var _a, _b, _c, _d, _e, _f;
        if (!this.needsPlugin(options) && !this.needsPlugin(source)) {
            return;
        }
        if (source === null || source === void 0 ? void 0 : source.emitters) {
            options.emitters = executeOnSingleOrMultiple(source.emitters, (emitter) => {
                const tmp = new Emitter();
                tmp.load(emitter);
                return tmp;
            });
        }
        const interactivityEmitters = (_b = (_a = source === null || source === void 0 ? void 0 : source.interactivity) === null || _a === void 0 ? void 0 : _a.modes) === null || _b === void 0 ? void 0 : _b.emitters;
        if (interactivityEmitters) {
            if (interactivityEmitters instanceof Array) {
                options.interactivity.modes.emitters = {
                    random: {
                        count: 1,
                        enable: true,
                    },
                    value: interactivityEmitters.map((s) => {
                        const tmp = new Emitter();
                        tmp.load(s);
                        return tmp;
                    }),
                };
            }
            else {
                const emitterMode = interactivityEmitters;
                if (emitterMode.value !== undefined) {
                    if (emitterMode.value instanceof Array) {
                        options.interactivity.modes.emitters = {
                            random: {
                                count: (_c = emitterMode.random.count) !== null && _c !== void 0 ? _c : 1,
                                enable: (_d = emitterMode.random.enable) !== null && _d !== void 0 ? _d : false,
                            },
                            value: emitterMode.value.map((s) => {
                                const tmp = new Emitter();
                                tmp.load(s);
                                return tmp;
                            }),
                        };
                    }
                    else {
                        const tmp = new Emitter();
                        tmp.load(emitterMode.value);
                        options.interactivity.modes.emitters = {
                            random: {
                                count: (_e = emitterMode.random.count) !== null && _e !== void 0 ? _e : 1,
                                enable: (_f = emitterMode.random.enable) !== null && _f !== void 0 ? _f : false,
                            },
                            value: tmp,
                        };
                    }
                }
                else {
                    const emitterOptions = (options.interactivity.modes.emitters = {
                        random: {
                            count: 1,
                            enable: false,
                        },
                        value: new Emitter(),
                    });
                    emitterOptions.value.load(interactivityEmitters);
                }
            }
        }
    }
    needsPlugin(options) {
        var _a, _b, _c;
        if (!options) {
            return false;
        }
        const emitters = options.emitters;
        return ((emitters instanceof Array && !!emitters.length) ||
            emitters !== undefined ||
            (!!((_c = (_b = (_a = options.interactivity) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b.onClick) === null || _c === void 0 ? void 0 : _c.mode) &&
                isInArray("emitter", options.interactivity.events.onClick.mode)));
    }
}
export async function loadEmittersPlugin(engine) {
    if (!engine.emitterShapeManager) {
        engine.emitterShapeManager = new ShapeManager(engine);
    }
    if (!engine.addEmitterShape) {
        engine.addEmitterShape = (name, shape) => {
            var _a;
            (_a = engine.emitterShapeManager) === null || _a === void 0 ? void 0 : _a.addShape(name, shape);
        };
    }
    const plugin = new EmittersPlugin(engine);
    await engine.addPlugin(plugin);
    engine.addEmitterShape("circle", new CircleShape());
    engine.addEmitterShape("square", new SquareShape());
}
export * from "./EmitterContainer";
export * from "./EmittersEngine";
export * from "./Enums/EmitterClickMode";
export * from "./Enums/EmitterShapeType";
